import React from 'react'
//import { GatsbyImage } from 'gatsby-plugin-image'
import Img from 'gatsby-image';
import styled from 'styled-components'

const Div = styled.div`
    height: 500px;
    width: 100%;
`;

const Slide = ({ content, alt }) => {

    return (
        <Div>
            <Img
                alt='zdjęcie banerowe'
                fluid={content.fluid} />
        </Div>
    )
}

export default Slide