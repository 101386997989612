import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ArrowWrapper = styled.div`
    display: flex;
    //padding: 0 .7rem 0 .7rem;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    //margin-top: -50%;
    //transform: translatey(-50%);
    height: auto;
    width: 100%;
    align-items: center;
`;

const Left = styled.div`
    cursor: pointer;
    padding: 0 .5rem .2rem .5rem;
    transition: all ease-in-out .5s;
    
    :hover {
        color: white;
        }
`;

const Right = styled(Left)`
    
`;

const Arrow = (props) => (
    <ArrowWrapper>
        <Left onClick={props.handleClickPrev}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </Left>
        <Right onClick={props.handleClickNext}>
            <FontAwesomeIcon icon={faChevronRight} />
        </Right>
    </ArrowWrapper>
)

export default Arrow