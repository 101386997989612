import React from "react";
import styled from 'styled-components';
import Img from 'gatsby-image';
import SEO from '../components/seo/SEO';
import Layout from '../layout/layout2';
import { Container, media } from '../utils/utils';
import Slider from "../components/slider/Slider";

const PracownikWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 2rem;
  margin-bottom: 2rem;
  ${media.lessThan("small")`
    grid-template-columns: 1fr;
  `}

  h6 {
    font-family: ${({ theme }) => theme.font.main};
    font-weight: 500;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
  }

  p {
    white-space: pre-wrap;
  }

  >div :nth-child(1) {
   height: 100%;
  }

  >div :nth-child(2) {
    padding: 2rem 0;
  }
`;

const PracownikWrapper2 = styled(PracownikWrapper)`
  grid-template-columns: 2fr 1fr;
`;

const Imagewrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  margin-bottom: 2rem;
  
  ${media.lessThan("small")`
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-top: 2rem;
    `}
`;

const IndexPage = ({ data }) => {
  const pracownicy = data.datoCmsIndexPage.pracownicy
  const zdjecia = data.datoCmsIndexPage.mainFotos
  return (
    <div>
      <SEO
        title="Home"
        slug='' />
      <Layout>

        <Container>
          <Slider slides={zdjecia} />
          {/* <Imagewrapper>
            {zdjecia.map((zdj) => (
              <Img
                fluid={zdj.fluid}
                alt='Zdjęcie salonu' />
            ))}
          </Imagewrapper> */}

          {pracownicy.map((pracownik) => {
            if (pracownicy.indexOf(pracownik) === 1)
              return (
                <PracownikWrapper>
                  <Img
                    fluid={pracownik.foto.fluid}
                    alt={pracownik.title} />
                  <div>
                    <h4>{pracownik.title}</h4>
                    <h6>{pracownik.wyksztalcenie}</h6>
                    <p>{pracownik.opis}</p>
                  </div>

                </PracownikWrapper>
              )
            else return (
              <PracownikWrapper2>
                <div>
                  <h4>{pracownik.title}</h4>
                  <h6>{pracownik.wyksztalcenie}</h6>
                  <p>{pracownik.opis}</p>
                </div>
                <Img
                  fluid={pracownik.foto.fluid}
                  alt={pracownik.title} />
              </PracownikWrapper2>
            )
          })}

        </Container>
      </Layout>
    </div>
  )
}
export default IndexPage

export const query = graphql`
query {
  datoCmsIndexPage {
    pracownicy {
      title
      wyksztalcenie
      opis
      foto {
        fluid(maxWidth: 600, 
          forceBlurhash: false, 
          imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    mainFotos{
      fluid(maxWidth: 2000, 
        forceBlurhash: false,
        imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
}`